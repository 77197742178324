import { getVendorCode } from "@/app/ui/modules/receipt/module";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";

export class ThermalReceiptData {
  sttProduct = "";
  sttUrsaCode = "";
  sttSla = "";
  sttDate = "";
  sttArrivalEstimationDate = "";
  sttNumber = "";
  sttNumberBarcode = "";
  sttDistrictDestination = "";
  sttOrigin3lc = "";
  sttDestination3lc = "";
  sttTransitOrigin3lc = "";
  sttTransitDestination3lc = "";
  sttSenderName = "";
  sttSenderFormattedName = "";
  sttSenderPhone = "";
  sttSenderAddress = "";
  sttRecipientName = "";
  sttRecipientFormattedName = "";
  sttRecipientPhone = "";
  sttRecipientAddress = "";
  sttRecipientDistrict = "";
  sttDestinationAddressType = "";
  sttCommodity = "";
  sttBookedBy = "";
  sttBarcodeVendor = "";
  sttVendorNumber = "";
  sttIsDo = false;
  isDangerousGoods = false;
  sttIsQuarantine = false;
  sttClient = "";
  sttExternalRefNumber = "";
  sttQrCode = "";
  sttTotalPrice = "";
  sttCodAmount = "-";
  sttShippingCost = "";
  sttInsuranceRate = "";
  sttSurchargeRate = "";
  sttBMRate = "";
  sttPPNRate = "";
  sttPPHRate = "";
  sttPiece = "";
  sttTotalPiece = "";
  sttWeight = "";
  sttTotalWeight = "";
  sttDimensionWeight = "";
  sttVendorPickupDetail = "";
  sttVendorName = "";
  sttVendorCode = "";
  sttPadAmount = "-";
  sttFooterText =
    "Download Aplikasi Lion Parcel melalui Appstore atau Playstore dan lacak paketmu sekarang!";
  sttWebLionParcelClaim = "www.lionparcel.com";
  sttWebLionParcelTos = "S&K : lionparcel.com/sk";
  sttLionParcelImage =
    "eJzt0TFOwzAYhmFXHrxU8gUQXjkCLDUbHKMbB2CASiUJytCNcAIys7EjQVCHLkhhZIEEdSgDIqk6NBVuPtySEqhqNqQOeQcPfuzB/gmpqqpa92hgNub8h9XNdordPyw2mjtNjeZPlckOfBht4AGBwfrRSstmy0ibsyyqtjD1bcL5ZZMQGVmymqrN39VKVlix004wLY0UVkulPtg4AhiIxTLh6o15NKNDdBFvHiJnuEOibE8tLKUjDBB71y8Z04eSCWRuL4wNtA17T41j3h2E4Udu59aXMW09bf17e5t3Ey8cp5ZVWuImvTgd44K7CY86gSrv7YUzUzt4Ftr8ToCsmAhHqOvFNseb6ETcZzGcHwbPC0Qf7wKRuGVK1UvzILyAAe8SPiRT1knxVwx+J9fWpHiT4JGkyh4WRuGfZoI7bYpXqZi0iBJ35a/Ps4FHa3m2RXKIB5NxF1cmIxs3zbaB2OTSMV1joy0TEXK2b7bGo9lafxg9N1tV1Zr1CY2M7ew";
  sttOriginCityName = "";
  sttLionParcelImageSmall =
    "^GFA,608,608,16,,:::::::::R018,R038,R0383,R038FC,K038K03BFF,001E078K0DBFF8R0F,001E078K0DBFFCQ01E,003E03L0DBFFR01E,003C070F85F0EFFE07F07E3F1E3E1E,003C0F3FCFF86DFF0FF8FF7F7E7F3C,007C0F7FCFFC37FE0F3DEF7EFCFFBC,00780F79EF7C9AB60F3DCF79E1E7BC,00781EF1DE78CDFF1E3F8F71E1FF3C,00FFDEF3DE78F3FF1E3B8EF1E1F078,00FFDEFF9E783DFF1FFBDEF1F9E378,007F9C7F3C7087FF1FF3FEF0FDFE78,007FBC3C3CF0IFE3DC19CE078707,R07CFA3C,S03FC3C,R01DE038,S036,S06A,,:::::";
  sttDangerousGoodsImage =
    "^GFA,600,600,10,O078,N01FE,N03FF,N0IFC,M01IFE,M03JF,M0KF8,L01KFE,L03LF,L07LF8,K01MFE,K03NF,K07NF8,J01OFE,J03PF,J07PF8,I01QFC,I03RF,I07RF8,I0SFC,003TF,007TF8,00IFC01IF01JFC,03IFC003FC00KF,07IFC001F8003JF8,0JFC001FI03JFC,3JFC3E0E0781JFE,7JFC3F060FE1KF87JFC3F061NF8KFC3F841E01KFCKFC3F841C01KFCKFC3F841C01KF87JFC3F041E01KF83JFC3F060FE1KF,0JFC3E0E07C1JFC,07IFCI0FI03JF8,03IFC001FI03JF,00IFC003FC00JFC,007FFC00IF01JF8,003TF,I0SFC,I07RF8,I03RF,I01QFE,J07PF8,J03PF,J01OFE,K07NF8,K03NF,K01MFE,L07LF8,L03LF,L01KFE,M0KFC,M03JF,M01IFE,N0IFC,N03FF,N01FE,O0F8,";
  sttQuarantineImage =
    "^GFA,600,600,10,O078,N01FE,N03FF,N0IFC,M01IFE,M03JF,M0KF8,L01KFE,L03LF,L07LF8,K01MFE,K03NF,K07NF8,J01OFE,J03PF,J07PF8,I01QFC,I03RF,I07RF8,I0SFC,003TF,007TF8,00KFE1F03KFC,03KFE0F07LF,07KFE0E0MF8,0LFE0C1MFC,3LFE081MFE,7LFE083NF87LFE007NF8MFE00OFC:MFE00OF87LFE007NF83LFE083NF,0LFE081MFC,07KFE0C1MF8,03KFE0E0MF,00KFE0F07KFC,007JFE0F03KF8,003TF,I0SFC,I07RF8,I03RF,I01QFE,J07PF8,J03PF,J01OFE,K07NF8,K03NF,K01MFE,L07LF8,L03LF,L01KFE,M0KFC,M03JF,M01IFE,N0IFC,N03FF,N01FE,O0F8,";
  sttScissorsImage =
    "^GFA,28,28,2,,:0C,1F,33,310833181F3,01E,00C,1FA,1B3,31183108";
  sttTotalGoodsTax = "-";
  sttPieceVolumeWeight = "";
  sttIsRts = false;
  sttIsRerouteMisroute = false;
  sttIsRerouteMisbooking = false;
  sttGoodsEstimatePrice = 0;
  sttCommodityDescription = "";
  isReverseJourneyAfterCancel = false;
  sttGoodsPrice = 0;
  sttReturnFee = 0;
  sttIsCodRetail = false;
  sttIsCod = false;
  sttIsDfod = false;
  sttCodFee = 0;
  totalTariffCodRetail = "";
  sttShipmentId = "";
  sttIsBigpack = false;
  sttIsJumbopack = false;
  sttTotalReturnFeeCod = 0;
  sttTotalReturnFeeCDfod = 0;
  sttBookedByCountry = "";
  sttIsWoodpacking = false;

  constructor(fields?: Partial<ThermalReceiptData>) {
    Object.assign(this, fields);
    this.sttQrCode = `https://lionparcel.com/track/stt?q=${this.sttNumber}`;
    const senderPhoneDestination = `, ${this.sttSenderPhone}, ${this.sttOriginCityName}`;
    this.sttSenderFormattedName = `${ellipsisString(
      this.sttSenderName,
      100 - senderPhoneDestination.length
    )}${senderPhoneDestination}`;
    this.sttRecipientAddress = ellipsisString(this.sttRecipientAddress, 200);
    this.sttRecipientFormattedName = `${ellipsisString(
      this.sttRecipientName,
      100 - this.sttRecipientPhone.length
    )}, ${this.sttRecipientPhone}`;
    this.sttRecipientDistrict = ellipsisString(this.sttDistrictDestination, 45);
    this.sttRecipientDistrict = ellipsisString(this.sttDistrictDestination, 60);
    this.sttCommodity = ellipsisString(this.sttCommodity, 80);
    this.sttBookedBy = ellipsisString(this.sttBookedBy, 45);
    this.sttVendorCode = getVendorCode(this.sttVendorName);
    this.sttExternalRefNumber = ellipsisString(this.sttExternalRefNumber, 30);
  }
}
